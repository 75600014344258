.composer {
  grid-column-start: 1;
  grid-column-end: 2;
}

.detail {
  grid-column-start: 2;
  grid-column-end: 3;
}

.App-main {
  display: grid;
  grid-template-columns: 30% auto;
}