.LandingPage{
  background-color: #5DBCD2;
  color: white;
  overflow: "hidden"
}

.LandingPage .detail{
  padding: 10% !important;
  padding-right: 2% !important;
  font-size: 20px;
  line-height: 1.6em;
}

.LandingPage .detail .header{
  color: white;
  font-size: 50px;
}

.LandingPage .pic{
  padding: 10% !important;
  padding-left: 2% !important;
}

.LandingPage .detail .header.lightgreen{
  color: #99EEBB;
}

.LandingPage .detail .whiteBasicButton{
  background-color: transparent; 
  color: white; 
  border: 2px solid white;
}