/* ReactTable.css react-lib */

.ReactTable {
    background-color: white;
}

.ReactTable .rt-thead.-header {
    background-color: #2F3E53;
    color: white;
    font-weight: bold;
}
.ReactTable .rt-th {
    border: 1px solid #ddd;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 3px 0 0 rgb(162, 232, 255);
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: inset 0 -3px 0 0 rgb(162, 232, 255);
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover{
    background: #e3ebf7;
}

.ReactTable .-pagination .-pageInfo {
    display: flex;
    align-items: center;
}

.ReactTable .-pagination .-pageInfo .-pageJump {
    margin-left: 1em;
    margin-right: 1em;
}

.ReactTable .-pagination .-pageInfo .-totalPages {
    margin-left: 1em;
    margin-right: 1em;
}

.ReactTable .rt-resizer {
    z-index: 1;
}

.ReactTable .rt-tr.selected {
    background-color: #00afec !important;
    color: white !important;
}

.ReactTable .rt-tr.blueSelectedRow,
.ReactTable.-striped .rt-tr.-odd.blueSelectedRow{
    background-color: #CCE2FF;
}

/* .ReactTable .rt-thead .rt-tr .rt-th {
    border: 1px solid gray;
    border-collapse: collapse;
}

.ReactTable .rt-tbody .rt-tr .rt-td {
    border: 1px solid gray;
    border-collapse: collapse;
} */

.ReactTable.-customTable .rt-thead .rt-tr .rt-th {
    border: 1px solid gray;
    border-collapse: collapse;
}

.ReactTable.-customTable .rt-tbody .rt-tr .rt-td {
    border: 1px solid gray;
    border-collapse: collapse;
}

.ReactTable.-customthead .rt-thead.-header .rt-tr {
    height: 60px !important;
}

.ReactTable.-customTablePadding .rt-td {
    padding: 0px 0px;
    text-align: center;
}

/* hide Page Size Options */
.ReactTable.-customTablePadding .-pagination .-pageSizeOptions {
    display: none;
}

.ReactTable.hidePageSizeOptions .-pagination .-pageSizeOptions {
    display: none;
}

.ReactTable.-customStrip3rows .rt-tbody .rt-tr-group:nth-child(6n + 1),
.ReactTable.-customStrip3rows .rt-tbody .rt-tr-group:nth-child(6n + 2),
.ReactTable.-customStrip3rows .rt-tbody .rt-tr-group:nth-child(6n + 3) {
    background-color: #f6ba52;
}

.ReactTable.-customStrip3rows .rt-tbody .rt-tr-group:nth-child(6n + 4),
.ReactTable.-customStrip3rows .rt-tbody .rt-tr-group:nth-child(6n + 5),
.ReactTable.-customStrip3rows .rt-tbody .rt-tr-group:nth-child(6n + 6) {
    background-color: #ffd180;
}

.ReactTable .rt-thead .rt-th, 
.ReactTable .rt-thead .rt-td,
.ReactTable .rt-thead .rt-header-content,
.ReactTable .rt-thead .rt-resizable-header-content{
    /* new line for header */
    white-space: pre-wrap;
}

.ReactTable .rt-tbody .rt-td.noPadding{
    /* How to: {
        Header: 'xxx',
        accessor: 'yyy',
        className: 'noPadding',
    } */
    padding: 0;
}

.ReactTable .rt-tbody{
    overflow: overlay;
 }

.ReactTable .rt-tbody .rt-td{
    /* new line for table body */
    white-space: pre-wrap;
    /* display: -webkit-inline-box; */
}

.ReactTable:not(.noHover) .rt-tr:not(.blueSelectedRow):not(.selected):hover .rt-td{
    /* color when hover */
    background: #EEF5FE
}

.ReactTable .HeaderCell{
    background-color: #2F3E53;
    color: white;
}

.ReactTable.greyBodyBorder .rt-tbody .rt-td{
    border: 0.5px solid #BDBDBD;
}

.ReactTable .rt-td.centerText{
    text-align: center;
}

.ReactTable .rt-td.focus{
    outline: -webkit-focus-ring-color auto 5px;
}

.ReactTable .rt-td.rt-expandable{
    overflow: visible;
}